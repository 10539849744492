<template>
  <div class="d-flex justify-start" style="align-items: center">
    <v-btn class="mr-1" width="24px" height="24px" outlined icon :color="color" @click.native="playing ? pause() : play()" :disabled="!loaded">
      <v-icon v-if="!playing || paused">{{ playIcon }}</v-icon>
      <v-icon v-else>{{ pauseIcon }}</v-icon>
    </v-btn>
<!--    <v-progress-linear v-model="percentage" height="5" style="margin-top: 15px; margin-bottom: 15px;" @click.native="setPosition()" :disabled="!loaded"></v-progress-linear>-->
    <span >{{ duration }}</span>

    <audio id="player" ref="player" v-on:ended="ended" v-on:canplay="canPlay" :src="file"></audio>
  </div>


</template>
<script>
    const formatTime = second => new Date(second * 1000).toISOString().slice(14, 19);

    export default {
        name: 'vuetify-audio',
        props: {
            flat: {
                type: Boolean,
                default: false
            },
            file: {
                type: String,
                default: null
            },
            autoPlay: {
                type: Boolean,
                default: false
            },
            ended: {
                type: Function,
                default: () => {},
            },
            canPlay: {
                type: Function,
                default: () => {},
            },
            color: {
                type: String,
                default: null
            },
            downloadable: {
                type: Boolean,
                default: false
            },
            playIcon: {
                type: String,
                default: 'mdi-play'
            },
            pauseIcon: {
                type: String,
                default: 'mdi-pause'
            },
            stopIcon: {
                type: String,
                default: 'mdi-stop'
            },
            refreshIcon: {
                type: String,
                default: 'mdi-refresh'
            },
            downloadIcon: {
                type: String,
                default: 'mdi-download'
            },
            volumeHighIcon: {
                type: String,
                default: 'mdi-volume-high'
            },
            volumeMuteIcon: {
                type: String,
                default: 'mdi-volume-mute'
            }
        },
        computed: {
            duration: function () {
                return this.audio ? formatTime(this.totalDuration) : ''
            },
        },
        data () {
            return {
                firstPlay: true,
                isMuted: false,
                loaded: false,
                playing: false,
                paused: false,
                percentage: 0,
                currentTime: '00:00:00',
                audio: undefined,
                totalDuration: 0,
                playerVolume: 0.5
            }
        },

        methods: {
            setPosition () {
                this.audio.currentTime = parseInt(this.audio.duration / 100 * this.percentage);
            },
            stop () {
                this.audio.pause()
                this.paused = true
                this.playing = false
                this.audio.currentTime = 0
            },
            play () {
                if (this.playing) return
                this.audio.play().then(_ => this.playing = true)
                this.paused = false
            },
            pause () {
                this.paused = !this.paused;
                (this.paused) ? this.audio.pause() : this.audio.play()
            },
            download () {
                this.audio.pause()
                window.open(this.file, 'download')
            },
            mute () {
                this.isMuted = !this.isMuted
                this.audio.muted = this.isMuted
            },
            reload () {
                this.audio.load();
            },
            _handleLoaded: function () {
              if (this.audio.readyState >= 2) {
                if (this.audio.duration === Infinity || isNaN(this.audio.duration)) {
                  this.audio.currentTime = Number.MAX_SAFE_INTEGER;
                  this.audio.ontimeupdate = () => {
                    this.audio.ontimeupdate = null;
                    this.audio.currentTime = 0;
                    this.totalDuration = this.audio.duration ? parseInt(this.audio.duration) : 0;
                    this.loaded = true;
                    if (this.autoPlay) this.audio.play();
                  };
                } else {
                  this.totalDuration = parseInt(this.audio.duration);
                  this.loaded = true;
                  if (this.autoPlay) this.audio.play();
                }
              } else {
                console.error('Failed to load sound file, readyState:', this.audio.readyState);
              }
            },
            _handlePlayingUI: function (e) {
                this.audio.volume = this.playerVolume
                this.percentage = this.audio.currentTime / this.audio.duration * 100
                this.currentTime = formatTime(this.audio.currentTime)
                this.playing = true
            },
            _handlePlayPause: function (e) {
                if (e.type === 'play' && this.firstPlay) {
                    // in some situations, audio.currentTime is the end one on chrome
                    this.audio.currentTime = 0;
                    if (this.firstPlay) {
                        this.firstPlay = false;
                    }
                }
                if (e.type === 'pause' && this.paused === false && this.playing === false) {
                    this.currentTime = '00:00:00'
                }
            },
            _handleEnded () {
                this.paused = this.playing = false;
            },
            init: function () {
              if (/iPad|iPhone|iPod/.test(navigator.userAgent)) this.audio.autoplay = true;
              this.audio.addEventListener('loadedmetadata', this._handleLoaded);
              this.audio.addEventListener('timeupdate', this._handlePlayingUI);
              this.audio.addEventListener('loadeddata', this._handleLoaded);
              this.audio.addEventListener('pause', this._handlePlayPause);
              this.audio.addEventListener('play', this._handlePlayPause);
              this.audio.addEventListener('ended', this._handleEnded);
            },
        },
        mounted () {
            this.audio = this.$refs.player;
            this.init();
        },
        beforeDestroy () {
          this.audio.removeEventListener('loadedmetadata', this._handleLoaded);
          this.audio.removeEventListener('timeupdate', this._handlePlayingUI)
          this.audio.removeEventListener('loadeddata', this._handleLoaded)
          this.audio.removeEventListener('pause', this._handlePlayPause)
          this.audio.removeEventListener('play', this._handlePlayPause)
          this.audio.removeEventListener('ended', this._handleEnded);
        }
    }
</script>
