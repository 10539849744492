<template>
    <v-container class="container-class" fluid @scroll="onScrollList"
                 style="overflow: auto; height: calc(100vh - 56px)">
        <div>
            <v-row class="mt-4 ml-1">
                <p class="header-title"> {{ $t('buy_code_management.otp_management') }} </p>
            </v-row>
            <v-row>
                <!--                range time on mobile-->
                <v-col cols="12" sm="4" v-if="isMobile">
                    <div class="d-flex flex-row align-center" style="column-gap: 8px">
                        <v-dialog
                                ref="dialog"
                                v-model="modalDatePicker"
                                :return-value.sync="dateRange"
                                persistent
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        background-color="#FFFFFF"
                                        v-model="rangeTimeText"
                                        label="Khoảng thời gian"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                        :dense="true"
                                        hide-details
                                        height="48px"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                    v-model="dateRange"
                                    scrollable
                                    range
                                    locale="vi"
                            >
                                <v-spacer></v-spacer>
                                <v-btn
                                        text
                                        color="primary"
                                        @click="modalDatePicker = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.dialog.save(dateRange)"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </div>
                </v-col>

                <v-col cols="12" sm="4">
                    <v-autocomplete
                            background-color="#ffffff" v-model="selectOTPStatus" :items="otpStatus"
                            item-text="title"
                            v-bind:label="$t('buy_code_management.status')" return-object outlined dense
                            hide-details
                            :height="isMobile ? '48px' : '40px'"
                    />
                </v-col>
                <v-col cols="12" sm="4">
                    <v-autocomplete
                            background-color="#ffffff" v-model="selectService"
                            :items="services" item-text="name"
                            v-bind:label="$t('buy_code_management.service')" return-object outlined dense
                            hide-details
                            :height="isMobile ? '48px' : '40px'"
                    />
                </v-col>
                <!--                search button on mobile-->
                <v-col cols="12" sm="4" v-if="isMobile">
                    <v-btn
                            width="100%" height="48px" style="font-size:17px"
                            color="primary" dark @click="onSearch"
                    >
                        {{ $t("common.search") }}
                    </v-btn>
                </v-col>
                <!--                range time and submit buttons on desktop-->
                <v-col cols="12" sm="4" v-if="!isMobile">
                    <div class="d-flex flex-row" style="column-gap: 8px">
                        <!--                        range time-->
                        <div class="d-flex flex-row align-center" style="column-gap: 8px">
                            <vc-date-picker v-model="startDate" :popover="{label: 'Hover', visibility: 'hover'}"
                                            style="width: 120px;">
                            </vc-date-picker>
                            <span>đến</span>
                            <vc-date-picker
                                    v-model="endDate" :popover="{label: 'Hover', visibility: 'hover'}"
                                    style="width: 120px;"
                            >
                            </vc-date-picker>
                        </div>
                        <!--submit buttons-->
                        <div class="d-flex flex-row justify-space-between" style="column-gap: 8px; flex: auto">
                            <v-btn style="flex: auto" color="primary" dark @click="onSearch">{{
                                $t("common.search")
                                }}
                            </v-btn>
                            <v-btn style="flex: auto" color="primary" dark @click="exportToExcel">
                                Export
                            </v-btn>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>

        <!--        table on desktop-->
        <template v-if="!isMobile">
            <v-data-table
                    :headers="headers"
                    :items="desserts"
                    :search="search"
                    :loading="loading"
                    sort-by="email"
                    class="elevation-1 mt-4"
                    hide-default-footer
                    :items-per-page="itemsPerPage"
                    @dblclick:row="editItemDB"
            >
                <!--      :item-class="this.$statusOTP"-->
                <template v-slot:top>
                    <v-toolbar flat color="white" class="pt-5 mb-2">
                        <v-row justify="start">
                            <v-col cols="12" sm="2">
                                <v-autocomplete v-model="itemsPerPage" :items="numberPage" outlined
                                                :dense="true"></v-autocomplete>
                            </v-col>

                            <v-col cols="12" sm="5">
                            </v-col>

                            <v-col cols="12" sm="5">
                                <v-text-field v-model="search" append-icon="search" v-bind:label="$t('common.search')"
                                              single-line hide-details outlined :dense="true"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-toolbar>

                    <div>
          <span class="defaultFont">Tổng giao dịch: <span class="defaultBoldFont"> {{
              $thousandSeprator(totalItem)
              }}</span>.
            Tổng tiền: <span class="defaultBoldFont"> {{ $thousandSeprator(totalMoney) }} </span> </span>
                    </div>
                </template>

                <template v-slot:[`item.payment`]="{ item }">
                    <span class="text-no-wrap">{{ $thousandSeprator(item.payment) }}</span>
                </template>

                <template v-slot:[`item.service`]="{ item }">
                    <div class="contentAddress">
                        <v-avatar size="30" class="mr-1">
                            <img v-if="item.service.image" :src="item.service.image"/>
                            <img v-else src='@/assets/no-photo.png'/>
                        </v-avatar>

                        <span class="text-no-wrap"> {{ item.service.name }} </span>
                    </div>

                </template>

                <template v-slot:[`item.otp`]="{ item }">
                    <div class="contentAddress">
                        <span class="text-no-wrap" style="display: inline-block; width: 60px;"> {{ item.otp }} </span>
                        <v-icon small class="mr-2" @click="copyClipboard(item.otp)" v-if="item.otp">
                            content_copy
                        </v-icon>
                    </div>
                </template>

                <template v-slot:[`item.user`]="{ item }">
                    <span class="text-no-wrap">{{ item.user.username }}</span>
                </template>

                <template v-slot:[`item.sim`]="{ item }">
                    <div class="contentAddress">
                        <span class="text-no-wrap" style="display: inline-block;"> {{ item.sim.phone }} </span>

                        <v-icon small class="mr-2 ml-1" @click="copyClipboard(item.sim.phone)"
                                v-if="item.sim.phone > 0">
                            content_copy
                        </v-icon>

                        <v-tooltip
                                bottom
                                max-width="300px"
                                color="primary"
                        >
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" size="12px" color="white" class="mr-2 iconBorderGreen"
                                        @click="renewSMS(item)" v-show="item.status === 1">
                                    autorenew
                                </v-icon>
                            </template>
                            <span>Mua lại</span>
                        </v-tooltip>
                    </div>
                </template>

                <template v-slot:[`item.create_date`]="{ item }">
                    <span class="text-no-wrap">{{ item.create_date | formatDate }}</span>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                    <span :class="$statusOTP(item)">{{ $OTPStatusString(item.status) }}</span>
                </template>

                <template v-slot:[`item.type`]="{ item }">
                    <span v-if="item.type !== 'audio'">{{ item.content }}</span>
                    <vuetify-audio v-else :file="item.audioMp3" color="primary"></vuetify-audio>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                    <div class="contentAddress d-flex justify-start">
                        <!--          <v-tooltip-->
                        <!--              bottom-->
                        <!--              max-width="300px"-->
                        <!--              color="primary"-->
                        <!--          >-->
                        <!--            <template v-slot:activator="{ on }">-->
                        <!--              <v-icon v-on="on" size="12px" color="white" class="mr-2 iconBorderOrange" @click="showContent(item)" v-show="item.status === 1 && item.type !== 'audio'">-->
                        <!--                info-->
                        <!--              </v-icon>-->
                        <!--            </template>-->
                        <!--            <span>Nội dung SMS</span>-->
                        <!--          </v-tooltip>-->

                        <v-tooltip
                                bottom
                                max-width="300px"
                                color="primary"
                        >
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" size="12px" color="white" class="mr-2 iconBorderRed"
                                        @click="cancelSMS(item)" v-show="item.status === 2">
                                    cancel
                                </v-icon>
                            </template>
                            <span>Hủy</span>
                        </v-tooltip>
                    </div>

                </template>

                <template v-slot:no-data>
                    <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
                </template>
            </v-data-table>
            <div class="text-center pt-2">
                <v-pagination v-model="page" :length="pageCount"></v-pagination>
            </div>
        </template>

        <!--        list item on mobile-->
        <template v-if="isMobile">
            <div class="mt-3 mb-2" style="font-size:17px">Tổng giao dịch: {{ totalItem }}</div>
            <InfoItem
                    v-for="item in desserts"
                    :key="item.id" :data="item"
                    :showDetailDialog="showDetailDialog"
                    :copyClipboardInDialog="copyClipboardInDialog"
                    :cancelSMS="cancelSMS"
                    :renewSMS="renewSMS"
            />
        </template>

        <v-dialog v-model="isShowContent" max-width="600px" v-if="editedItem">
            <v-card class="elevation-16 mx-auto">
                <v-card-title primary-title>
                    Nội dung tin nhắn
                </v-card-title>

                <v-card-text>
                    <v-card-text class="px-1">
                        <span> Số gửi: <span class="bold-title"> {{ editedItem.sender_name }} </span> </span> <br/>
                        <span> Thời gian: <span class="bold-title"> {{
                            editedItem.create_date | formatDate
                            }} </span> </span> <br/>
                        <span> Nội dung: <span class="bold-title"> {{ editedItem.content }} </span> </span> <br/>
                    </v-card-text>
                </v-card-text>

                <v-divider></v-divider>
                <v-card-actions class="justify-space-between">
                    <v-spacer></v-spacer>
                    <v-btn dark color="primary" @click="closeContentDialog" width="120px">
                        {{ $t('common.close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!--        detail item on mobile-->
        <v-dialog v-model="visibleDetailDialog" width="100%" v-if="isMobile && !!detailItem">
            <v-card class="elevation-16 pa-2">
                <div class="d-flex align-center" style="column-gap: 12px">
                    <v-avatar size="64">
                        <img v-if="detailItem.service.image" :src="detailItem.service.image"/>
                        <img v-else src='@/assets/no-photo.png'/>
                    </v-avatar>
                    <div class="detail-content" style="width: calc(100% - 76px)">
                        <div class="d-flex justify-space-between" style="width: 100%">
                            <div class="font-weight-bold" style="font-size:17px">{{ detailItem.service.name }}</div>
                            <v-avatar size="16">
                                <img src='@/assets/close_icon.svg' @click="closeDetailDialog"/>
                            </v-avatar>
                        </div>
                        <div class="wrap-text">
                            {{ $t('buy_code_management.create_date') }}: {{ detailItem.create_date | formatDate }}
                        </div>
                        <div class="wrap-text">
                            Sender name: {{ detailItem.sender_name }}
                        </div>
                        <div class="wrap-text">
                            {{ $t('buy_code_management.phone') }}: {{ detailItem.sim.phone }}
                            <v-icon color="primary" small class="mr-2"
                                    @click="copyClipboardInDialog(detailItem.sim.phone)"
                                    v-if="detailItem.sim.phone">
                                file_copy
                            </v-icon>
                        </div>
                        <div>
                            Nội dung: <span v-if="detailItem.type !== 'audio'">{{ detailItem.content }}</span>
                            <vuetify-audio v-else :file="detailItem.audioMp3" color="primary"></vuetify-audio>
                        </div>
                        <div class="wrap-text">
                            {{ $t('buy_code_management.code') }}: {{ detailItem.otp }}
                            <v-icon color="primary" small class="mr-2" @click="copyClipboardInDialog(detailItem.otp)"
                                    v-if="detailItem.otp">
                                file_copy
                            </v-icon>
                        </div>
                    </div>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="isShowContent" max-width="600px" v-if="editedItem">
          <v-card class="elevation-16 mx-auto">
            <v-card-title>
              <span class="header-title">Nội dung tin nhắn</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-card-text class="px-1">
                <span> Thời gian: <span class="bold-title"> {{ editedItem.create_date | formatDate }} </span> </span> <br/>
                <span> Số gửi: <span class="bold-title"> {{ editedItem.sender_name }} </span> </span> <br/>
                <span> Nội dung: <span class="bold-title"> {{ editedItem.content }} </span> </span> <br/>
              </v-card-text>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions class="justify-space-between">
              <v-spacer></v-spacer>
              <v-btn dark color="primary" @click="closeContentDialog" width="120px">
                {{ $t('common.close') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-snackbar
                v-model="snackbar"
                :color="color"
                :timeout="2000"
                top
                right
                elevation="24"
                class="mt-5"
        >
            {{ message }}
        </v-snackbar>

        <v-overlay :value="overlay" :z-index="0">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>
import i18n from '@/plugins/i18n';
import socketClient from "@/plugins/socketClient";
import moment from 'moment'
import * as XLSX from "xlsx";
import {isMobileBrowser} from "@/utils/Device";
import InfoItem from "@/components/InfoItem.vue";
import {ref} from "vue";

export default {
    components: {InfoItem},
    data: () => ({
        overlay: false,
        totalItem: 0,
        totalMoney: 0,
        page: 1,
        pageCount: 0,
        itemsPerPage: 50,
        loading: false,
        headerNumber: '',
        numberPage: [
            50, 100, 300, 500, 1000
        ],
        startDate: new Date(),
        endDate: new Date(),

        selectNetwork: {},
        networks: [],

        selectService: {name: i18n.t("common.all"), id: -1},
        services: [],
        isShowContent: false,
        editedItem: null,

        snackbar: false,
        message: '',
        color: '#0C8F4D',

        search: '',
        dialog: false,
        headers: [
            {text: '#', align: 'left', sortable: false, value: 'index',},
            {text: i18n.t('buy_code_management.create_date'), value: 'create_date', sortable: false},
            // { text: i18n.t('buy_code_management.customer'), value: 'user', sortable: false },

            {text: i18n.t('buy_code_management.service'), value: 'service', sortable: false},
            {text: i18n.t('buy_code_management.price'), value: 'payment', sortable: false},

            {text: i18n.t('buy_code_management.phone'), value: 'sim', sortable: false},
            {text: i18n.t('buy_code_management.code'), value: 'otp', sortable: false},
            {text: 'Nội dung', value: 'type', sortable: false},
            {text: i18n.t('buy_code_management.status'), value: 'status', sortable: false},
            {text: '', align: 'left', value: 'action', sortable: false},
        ],

        otpStatus: [
            {title: i18n.t("common.all"), value: -1},
            {title: i18n.t("buy_code_management.success"), value: 1},
            {title: i18n.t("buy_code_management.waiting"), value: 2},
            {title: i18n.t("buy_code_management.cancel"), value: 3},
            {title: i18n.t("buy_code_management.timeout"), value: 0},
        ],

        selectOTPStatus: {title: i18n.t("common.all"), value: -1},

        desserts: [],
        stringRules: [
            v => !!v || i18n.t('validate.not_empty'),
            // v => (v && v.length > 5) || i18n.t('validate.string_invalid')
        ],

        attributes: [
            // This is a single attribute
            {
                popover: {label: 'Hover', visibility: 'hover'}
            }
        ],
        selectedRows: [],
        visibleDetailDialog: false,
        detailItem: null,
        dateRange: null,
        modalDatePicker: false
    }),

    computed: {
        selectDragAttribute() {
            return {
                popover: {
                    visibility: 'hover',
                    isInteractive: false, // Defaults to true when using slot
                },
            };
        },
        isMobile() {
            return isMobileBrowser()
        },
        rangeTimeText() {
            if (this.dateRange?.length !== 2) return ''
            const startDateTxt = moment(this.dateRange[0], "YYYY-MM-DD").format("MM/DD/YYYY")
            const endDateTxt = moment(this.dateRange[1], "YYYY-MM-DD").format("MM/DD/YYYY")
            return `${startDateTxt} ~ ${endDateTxt}`
        }
    },

    watch: {
        itemsPerPage() {
            this.page = 1;
            this.getAllOTP();
        },

        page() {
            // trên mobile thì nối tiếp danh sách list, k reset về [] trước khi lấy
            this.getAllOTP(!this.isMobile);
        },
        dateRange(val) {
            if (val?.length !== 2) return
            const format = "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
            this.startDate = moment(val[0], "YYYY-MM-DD").startOf("day").format(format)
            this.endDate = moment(val[1], "YYYY-MM-DD").endOf("day").format(format)
        }
    },

    async created() {
        await this.getAllOTP()
        await this.getAllService()

        socketClient.getIO().on('notifications-otp', (msg) => {
            this.desserts.forEach((element, index) => {
                if (element.sim.phone === msg.phone && element.status == 2) {
                    this.desserts[index].create_date = msg.time
                    this.desserts[index].status = msg.status;

                    if (msg.status === 1) {
                        // this.desserts[index].create_date = msg.time
                        // this.desserts[index].otp = msg.code;
                        // this.desserts[index].status = msg.status;
                        // this.desserts[index].content = msg.content;
                        Object.assign(this.desserts[index], msg);
                    }

                    return;
                }
            });
        });
    },

    methods: {
        async getAllOTP(resetData = true) {
            try {
                const from = this.$formatStartUTCDate(this.startDate);
                const to = this.$formatEndUTCDate(this.endDate);

                this.loading = true;
                const response = await this.$getAllOTPNotSpam(this.selectService.id, this.selectOTPStatus.value, this.page - 1, this.itemsPerPage, from, to);
                this.loading = false;

                if (response.status === 200) {
                    if (resetData || this.page === 1) {
                        this.desserts = [].concat(response.data.content);
                    } else {
                        this.desserts = [...this.desserts, ...response.data.content];
                    }
                    this.pageCount = response.data.totalPages;
                    this.totalItem = response.data.totalElements;
                    // this.totalMoney = response.data.totalPayment;

                    this.desserts.forEach(
                        (item, index) => {
                            item.index = (this.page - 1) * this.itemsPerPage + index + 1;
                        }
                    );
                }
            } catch (e) {
                console.log(e.stack);
            }
        },

        async exportToExcel() { // On Click Excel download button
            this.overlay = true
            const from = this.$formatStartUTCDate(this.startDate);
            const to = this.$formatEndUTCDate(this.endDate);

            const response = await this.$getAllOTPNotSpam(this.selectService.id, this.selectOTPStatus.value, this.page - 1, this.totalItem, from, to);
            this.overlay = false

            if (response.data && response.status === 200) {
                const responseData = response.data.content;
                const rows = responseData.map(row => ({
                    create_date: moment.utc(String(row.create_date)).local().format("HH:mm:ss DD/MM/YYYY"),
                    service: row.service.name,
                    payment: row.payment,
                    phone: row.sim.phone,
                    code: row.otp,
                    status: this.$OTPStatusString(row.status)
                }));

                const worksheet = XLSX.utils.json_to_sheet(rows);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "OTP");

                XLSX.utils.sheet_add_aoa(worksheet, [[
                    "Thời gian",
                    "Dịch vụ",
                    "Giá tiền",
                    "Số điện thoại",
                    "Code",
                    "Trạng thái"]], {origin: "A1"});
                /* calculate column width */
                const max_width_date = rows.reduce((w, r) => Math.max(w, r.create_date.length), 15);

                worksheet["!cols"] = [
                    {wch: max_width_date},
                    {wch: max_width_date},
                    {wch: max_width_date},
                    {wch: max_width_date},
                    {wch: max_width_date},
                    {wch: max_width_date}
                ];
                // export Excel file
                XLSX.writeFile(workbook, "OTP.xlsx", {compression: true});
            }
        },

        async cancelSMS(item) {
            let index = this.desserts.indexOf(item);

            const response = await this.$cancelSIM(item.sim.id);
            const self = this;
            if (response.status === 200) {
                self.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
                self.message = 'Hủy thành công!';
                self.snackbar = true;
                this.desserts[index].status = 3;
                // await this.getAllOTP();
            } else {
                self.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
                self.message = 'Hủy không thành công!';
                self.snackbar = true;
            }
        },

        async renewSMS(item) {
            try {
                this.overlay = true;
                const response = await this.$renewSim(item.sim.id, item.service.id)
                this.overlay = false;

                if (response.status === 200) {
                    this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
                    this.message = 'Lấy lại số thành công!'
                    this.snackbar = true;

                    this.desserts.unshift(response.data);
                    this.desserts.forEach(
                        (item, index) => {
                            item.index = (this.page - 1) * this.itemsPerPage + index + 1;
                        }
                    );
                } else if (response.status === 312) {
                    this.color = process.env.VUE_APP_WARNING_COLOR_TOAST;
                    this.message = response.data
                    this.snackbar = true;

                    return;
                } else if (response.data) {
                    this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
                    this.message = response.data;
                    this.snackbar = true;

                    return;
                } else {
                    this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
                    this.message = i18n.t('validate.request_error')
                    this.snackbar = true;
                }
            } catch (e) {
                console.log(e.stack);
                alert(i18n.t('validate.request_error'));
            }
        },

        copyClipboard(value) {
            const self = this;
            this.$copyText(value).then(function () {
                self.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
                self.message = 'Copy thành công ' + value;
                self.snackbar = true;
            }, function () {
                self.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
                self.message = 'Copy không thành công ' + value;
                self.snackbar = true;
            })
        },
        // vue-clipboard2 not support text in dialog
        copyClipboardInDialog(value) {
            const el = document.createElement('textarea');
            el.addEventListener('focusin', e => e.stopPropagation());
            el.value = value;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
            this.message = 'Copy thành công ' + value;
            this.snackbar = true;
        },

        async getAllService() {
            try {
                const response = await this.$getAllService(0, 1000);

                if (response.status === 200) {
                    this.services = [{name: i18n.t("common.all"), id: -1}].concat(response.data.content);
                }
            } catch (e) {
                console.log(e.stack);
            }
        },

        showContent(item) {
            this.editedItem = item;

            this.isShowContent = true;
        },

        closeContentDialog() {
            this.isShowContent = false;
            this.editedItem = null;
        },
        onScrollList() {
            const container = document.querySelector('.container-class');
            const scrollPosition = container.scrollTop + container.offsetHeight;
            const totalHeight = container.scrollHeight;
            if (scrollPosition >= totalHeight && this.page < this.pageCount && !this.loading) {
                this.page += 1
            }
        },
        onSearch() {
            if (this.page === 1) {
                this.getAllOTP()
            } else {
                this.page = 1
            }
        },
        showDetailDialog(item) {
            this.visibleDetailDialog = true
            this.detailItem = item
        },
        closeDetailDialog() {
            this.visibleDetailDialog = false
            this.detailItem = null
        },

        editItemDB(event, {item}) {
          if (item.status === 1) {
            this.editedIndex = this.desserts.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.isShowContent = true;
          }
        }
    }
}
</script>

<style scoped>
tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
}

.bank-title {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
}

.bold-title {
    font-style: normal;
    font-weight: bold;
}

.notice-title {
    color: #FF0000;
    font-style: normal;
    font-weight: bold;
}

.v-btn {
    text-transform: unset !important;
}

.wrap-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.detail-content > div {
    margin-bottom: 8px
}
</style>
